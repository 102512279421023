// // Here you can add other styles
// @include themes($footer-theme-map, $parent: ".c-footer") {
//   // color: themes-get-value("footer-color");
//   // background: themes-get-value("footer-bg");
//   background: black;
//   // @include borders(themes-get-value("footer-borders"));
// }

body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol, sans-serif" !important;
}

html,
body {
  overflow-x: hidden;
}

.c-app:not(.c-legacy-theme):not(.c-dark-theme) .c-header.c-header-fixed {
  border: 1px solid #cfcdcd;
  box-shadow: none;
}

h4,
.h4 {
  font-size: 1.2rem;
}

h3,
.h3 {
  font-size: 1.7rem;
}
